import React, { } from 'react';

import TrackableCTA from 'components/utils/TrackableCTA';
import * as style from 'styles/components/partners/partnershipPage.module.scss';
import TextEditorRender from 'components/utils/TextEditorRender';

const LinkBox = ({ header, description, cta, slug }) => (
    <div className="wrapper">
        <div className={style.linkBox__container}>
            <h2 className="title-xs-mobile title-md semibold">{header}</h2>
            <div className={style.linkBox__text}>
                <TextEditorRender content={description} />
            </div>
            <TrackableCTA
                ctaText={header}
                location="Partner Linkbox"
                destination={slug}
            >
                <a href={slug} className="cta-yellow">{cta}</a>
            </TrackableCTA>
        </div>
    </div>
)

export default LinkBox;
