import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/partners/partnershipPage.module.scss';
import { LangContext } from 'context/LangContext';
import TrackableCTA from 'components/utils/TrackableCTA';
import FormattedMessage from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

const PartnershipCases = ({ partners }) => {
    const { lang } = useContext(LangContext);

    return (
        <section className={`${style.container} wrapper`}>
            <h2 className="title-xs-mobile title-md semibold">
                <FormattedMessage id="partners.ourMainPartners" />
            </h2>

            {partners.map((partner) => (
                <div key={partner._id} className={style.partner__container}>
                    <div className={style.partner__header}>
                        <TrackableCTA
                            ctaText={partner.title}
                            location="Partner page - Case"
                            destination={partner.url}
                        >
                            <a href={partner.url} target="_blank" rel="noreferrer">
                                <ImageGatsby
                                    {...partner.logoWhite}
                                    alt={partner.title}
                                    title={partner.title}
                                />
                            </a>
                        </TrackableCTA>

                        {partner.keyPoints.length !== 0 && (
                            <ul className={style.partner__keyPoints}>
                                {partner.keyPoints
                                    .filter((keyPoint) => keyPoint[lang.translationKey])
                                    .map((keyPoint) => (
                                        <li key={keyPoint._key}>{keyPoint[lang.translationKey]}</li>
                                    ))
                                }
                            </ul>
                        )}
                    </div>

                    {partner.fullDescription && partner.fullDescription[lang.translationKey] && (
                        <div className={style.partner__content}>
                            <TextEditorRender content={partner.fullDescription[lang.translationKey]._rawContent} />
                        </div>
                    )}
                </div>
            ))}
        </section>
    );
};

export default PartnershipCases;
